import {
  IconDogGrooming,
  IconDogTraining,
  IconDogWalking,
  IconHouseVisiting,
  IconPetDayCare,
  IconPetSitting,
} from '@madpaws/design-system';

import { Overnight } from './components/Overnight/Overnight';

import type { ReactNode } from 'react';

type Service = {
  description: string;
  icon: ReactNode;
  tag?: ReactNode;
  title: string;
};

export const SERVICES: Service[] = [
  {
    title: 'Hosting',
    description:
      'Your pet stays at your sitter’s home. The purfect petcation with all the creature comforts.',
    icon: <IconPetSitting size="large" />,
    tag: <Overnight />,
  },
  {
    title: 'Sitting',
    description:
      'Your sitter stays with your pet in your home. Best in show care where your pet is most comfortable.',
    icon: <IconPetSitting size="large" />,
    tag: <Overnight />,
  },
  {
    title: 'Day care',
    description:
      'Busy day, no problems! Leave your pet with your sitter for the day and return to a happy furbaby.',
    icon: <IconPetDayCare size="large" />,
  },
  {
    title: 'House visiting',
    description:
      'The sitter drops in to visit your pet, giving them food, water and a toilet break.',
    icon: <IconHouseVisiting size="large" />,
  },
  {
    title: 'Dog walking',
    description:
      'Pups need a workout too. Your sitter will walk them in your local area to burn off their extra energy.',
    icon: <IconDogWalking size="large" />,
  },
  {
    title: 'Dog grooming',
    description:
      'A perfectly coiffed canine will await you when you book with one of our groomers.',
    icon: <IconDogGrooming size="large" />,
  },
  {
    title: 'Dog training',
    description:
      'Your purfect pooch not always perfect? Let one of our talented trainers teach them.',
    icon: <IconDogTraining size="large" />,
  },
];
